.App {
  /* Custom app-wide styling can go here if we feel inclined */
}

/* alerts */

.alert-info {
  color: #1d7aaf;
  background-color: #d3eaf8;
  border-color: #7bc1ea;
}

.alert-orange {
  color: #f79822;
  background-color: #fffdfa;
  border-color: #ffca40;
}

.alert-orange a {
  color: #b77119;
}

.skip a {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip a:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 16px;
}

/* 
    These are hacks to make it so we can run marked over a set of text & dump the html into a span
      while still having it look cohesive with the rest of the elements in the alert box
*/
.alert > * > p,
.alert > * > ul {
  margin-bottom: 0;
}
.alert > * > p + p {
  margin-top: 5px;
}
.alert > span > p {
  margin-top: 5px;
}

/* vertical dividers */

/* medium+ */
@media (min-width: 992px) {
  .lv-divide {
    border-left: 1px solid #e0e0e0;
  }
}

/* large+ */
@media (min-width: 1200px) {
  .lv-divide-lg {
    border-left: 1px solid #e0e0e0;
  }
}

.only-print {
  display: none;
}

@media print {
  .only-print {
    display: block;
  }
}

@media print {
  .no-print {
    display: none !important;
  }
}
