/********************************************\
| Credit Card Icons                          |
\********************************************/
.cc-icon {
  display: inline-block;
  width: 30px;
  height: 20px;
}
.amex-icon {
  background: url("img/american-express-curved-64px.png");
  background-size: 30px 20px;
  background-repeat: no-repeat;
}
.amex-icon-highlight {
  background: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url("img/american-express-curved-64px.png");
  background-size: 30px 20px;
  background-repeat: no-repeat;
}
.discover-icon {
  background: url("img/discover-curved-64px.png");
  background-size: 30px 20px;
  background-repeat: no-repeat;
}
.discover-icon-highlight {
  background: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url("img/discover-curved-64px.png");
  background-size: 30px 20px;
  background-repeat: no-repeat;
}
.mc-icon {
  background: url("img/mastercard-curved-64px.png");
  background-size: 30px 20px;
  background-repeat: no-repeat;
}
.mc-icon-highlight {
  background: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url("img/mastercard-curved-64px.png");
  background-size: 30px 20px;
  background-repeat: no-repeat;
}
.visa-icon {
  background: url("img/visa-curved-64px.png");
  background-size: 30px 20px;
  background-repeat: no-repeat;
}
.visa-icon-highlight {
  background: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url("img/visa-curved-64px.png");
  background-size: 30px 20px;
  background-repeat: no-repeat;
}
